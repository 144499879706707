import { getUserProfile } from '../auth';
import { getOrganizationsList } from '../organizations';
import { isVendorEnabled } from '../sdk';

const appId = CONFIG.intercom.appId;

/**
 * JavaScript tag to enable Intercom (provided by them)
 */
const SCRIPT_FUNCTION = `(function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/${appId}';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
    })();
    `;

/**
 * Check if intercom is booted
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
const isBooted = (intercom: any): boolean => {
  return typeof intercom !== 'undefined' && intercom.booted;
};

/**
 * Add Intercom's script tag to the head
 */
const addIntercomTag = () => {
  const loaderId = 'intercom-script-loader';
  const scriptExists = document.getElementById(loaderId);

  if (scriptExists) {
    console.error('Intercom is already loaded');
    return;
  }

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.textContent = SCRIPT_FUNCTION;
  script.id = loaderId;

  document.head.appendChild(script);
};

/**
 * Initializes Intercom
 * @returns {void}
 */
// istanbul ignore next
export const initIntercom = async (): Promise<void> => {
  if (!isVendorEnabled('c:intercom')) {
    console.warn('User has not given consent for loading intercom');
    return;
  }

  addIntercomTag();

  const windowRef: WindowModel = window;

  if (isBooted(windowRef.Intercom)) {
    console.error('Intercom is already booted');
    return;
  }

  const profile = await getUserProfile();
  const organizations = getOrganizationsList();
  const email = profile.email;
  const name = profile.name;
  windowRef.Intercom('boot', {
    app_id: appId,
    name,
    email,
    Organizations: organizations?.map(organization => organization.id).join(','),
  });
};

/**
 * Show the intercom chat
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomupdate
 */
export const showIntercom = () => {
  const windowRef: WindowModel = window;

  windowRef.Intercom && windowRef.Intercom('show');
};

/**
 * Update intercom (checks for new messages)
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomupdate
 */
export const updateIntercom = () => {
  const windowRef: WindowModel = window;

  windowRef.Intercom && windowRef.Intercom('update');
};
