// Copied from https://gitlab.com/didomi/console/-/blob/master/src/app/shared/services/i18n/fixtures/supported-languages.ts
export var supportedLanguages = [
    { longName: 'Arabic', code: 'ar' },
    { longName: 'Arabic (Jordan)', code: 'ar-JO' },
    { longName: 'Azerbaijani', code: 'az-AZ' },
    { longName: 'Bengali', code: 'bn-IN' },
    { longName: 'Brazilian', code: 'pt-BR' },
    { longName: 'Bulgarian', code: 'bg' },
    { longName: 'Catalan', code: 'ca' },
    { longName: 'Chinese Simplified', code: 'zh-CN' },
    { longName: 'Chinese Traditional', code: 'zh-TW' },
    { longName: 'Croatian', code: 'hr' },
    { longName: 'Czech', code: 'cs' },
    { longName: 'Danish', code: 'da' },
    { longName: 'Dutch', code: 'nl' },
    { longName: 'Dutch (Belgium)', code: 'nl-BE' },
    { longName: 'English', code: 'en' },
    { longName: 'English (New Zealand)', code: 'en-NZ' },
    { longName: 'English (United Kingdom)', code: 'en-GB' },
    { longName: 'Estonian', code: 'et' },
    { longName: 'Filipino', code: 'fil' },
    { longName: 'Finnish', code: 'fi' },
    { longName: 'French', code: 'fr' },
    { longName: 'French (Belgium)', code: 'fr-BE' },
    { longName: 'French (Canada)', code: 'fr-CA' },
    { longName: 'German', code: 'de' },
    { longName: 'German (Austria)', code: 'de-AT' },
    { longName: 'German (Switzerland)', code: 'de-CH' },
    { longName: 'Greek', code: 'el' },
    { longName: 'Hebrew', code: 'he' },
    { longName: 'Hindi', code: 'hi-IN' },
    { longName: 'Hungarian', code: 'hu' },
    { longName: 'Indonesian', code: 'id' },
    { longName: 'Italian', code: 'it' },
    { longName: 'Japanese', code: 'ja' },
    { longName: 'Korean', code: 'ko' },
    { longName: 'Latvian', code: 'lv' },
    { longName: 'Lithuanian', code: 'lt' },
    { longName: 'Macedonian', code: 'mk-MK' },
    { longName: 'Malay', code: 'ms' },
    { longName: 'Norwegian', code: 'no' },
    { longName: 'Polish', code: 'pl' },
    { longName: 'Portuguese', code: 'pt' },
    { longName: 'Romanian', code: 'ro' },
    { longName: 'Russian', code: 'ru' },
    { longName: 'Serbian', code: 'sr' },
    { longName: 'Serbian (Latin)', code: 'sr-Latn' },
    { longName: 'Slovak', code: 'sk' },
    { longName: 'Slovenian', code: 'sl' },
    { longName: 'Spanish', code: 'es' },
    { longName: 'Swahili', code: 'sw' },
    { longName: 'Swedish', code: 'sv' },
    { longName: 'Thai', code: 'th' },
    { longName: 'Turkish', code: 'tr' },
    { longName: 'Ukrainian', code: 'uk' },
    { longName: 'Vietnamese', code: 'vi' },
];
