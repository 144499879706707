import { getActiveOrganizationId } from '../organizations';
import { DidomiUtilityEventMap } from '../typings/didomiUtilityEvents';
import { getUserProfile } from '../user-profile';
import { getDevAccessPolicies } from './seed-access-policies';

/**
 * Get current user's policies for active organization
 */
const getCurrentAccessPolicies = async () => {
  const currentOrgId = getActiveOrganizationId() as string;
  const currentUser = await getUserProfile();
  const currentUserWithSeeds = { ...(currentUser || {}), ...getDevAccessPolicies() };

  return currentUserWithSeeds.roles?.[currentOrgId]?.access_policy_ids || [];
};

/**
 * Emit `accessPoliciesChanged` event with current policies
 */
const emitAccessPoliciesChange = async () => {
  const accessPolicies = await getCurrentAccessPolicies();

  const policiesChangeEvent: DidomiUtilityEventMap['accessPoliciesChanged'] = new CustomEvent('accessPoliciesChanged', { detail: accessPolicies });
  window.dispatchEvent(policiesChangeEvent);
};

/**
 * Updated policies every-time an organization changes or the first time they are fetch
 */
export const connectAccessManagement = (): void => {
  window.addEventListener('orgSetup', emitAccessPoliciesChange);
  window.addEventListener('orgChanged', emitAccessPoliciesChange);
};

/**
 * It removes the event listener that expects an organization change to update the access policies
 */
export const disconnectAccessManagement = (): void => {
  window.removeEventListener('orgSetup', emitAccessPoliciesChange);
  window.removeEventListener('orgChanged', emitAccessPoliciesChange);
};

/**
 * Check if logged user has access policies for active organization
 *
 * @param {string[]} policies The policies to check against the user profile
 * @param {boolean} isExact Whether the exact check should be performed(all provided policies should be assigned)
 * @returns {Promise<boolean>}
 */
export const hasAccessPolicies = async (policies: readonly string[], isExact = false): Promise<boolean> => {
  const accessPolicies = await getCurrentAccessPolicies();
  // Check if policy is assigned to user
  const matchFn = (policy: string) => accessPolicies?.includes(policy);

  return isExact ? policies.every(matchFn) : policies.some(matchFn);
};
