import { supportedLanguages } from '../supported-languages';
var AVAILABLE_LANGUAGES = supportedLanguages.map(function (sL) { return sL.code; });
/**
 * Returns the language code that is going to be selected from the propertyObject
 * based on the string availability.
 *
 * @returns {(string|null)} -> null means the translation is directly a string or empty translation object.
 */
export var getSelectedLanguageCode = function (propertyObject, defaultLanguageCode) {
    if (defaultLanguageCode === void 0) { defaultLanguageCode = 'en'; }
    if (typeof propertyObject === 'string') {
        return null;
    }
    if (propertyObject === null || propertyObject === void 0 ? void 0 : propertyObject[defaultLanguageCode]) {
        return defaultLanguageCode;
    }
    // If there's no value in default language we return a value in any language
    var languageAvailable = AVAILABLE_LANGUAGES.find(function (language) { return !!(propertyObject === null || propertyObject === void 0 ? void 0 : propertyObject[language]); });
    return languageAvailable !== null && languageAvailable !== void 0 ? languageAvailable : null;
};
export var getTranslatedValue = function (propertyObject, defaultValue, defaultLanguageCode, fillIfEmpty) {
    if (defaultValue === void 0) { defaultValue = '-'; }
    if (defaultLanguageCode === void 0) { defaultLanguageCode = 'en'; }
    if (fillIfEmpty === void 0) { fillIfEmpty = true; }
    var languageCode = fillIfEmpty ? getSelectedLanguageCode(propertyObject, defaultLanguageCode) : defaultLanguageCode;
    if (languageCode === null) {
        if (typeof propertyObject === 'string') {
            return propertyObject;
        }
        // This case will be possible when the propertyObject is empty -> `{}`
        return defaultValue;
    }
    return (propertyObject === null || propertyObject === void 0 ? void 0 : propertyObject[languageCode]) || defaultValue;
};
