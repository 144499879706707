import { getToken } from '../auth';
import { getActiveUserProfile } from '../user-profile';
import { environment } from '../constants';
import { HubspotDomainModel } from './models/hubspot-domain.model';
import { isVendorEnabled } from '../sdk';

const getHubspotToken = async (): Promise<string> => {
  const hubspotRequest = await fetch(`${environment.apiBaseUrl}/hubspot/visitors`, {
    method: 'GET',
    headers: new Headers({
      accept: 'application/json',
      Authorization: `Bearer ${getToken()}`,
    }),
  });
  const hubspotDomains: [HubspotDomainModel] = (await hubspotRequest.json())?.data;

  return hubspotDomains[0]?.token;
};

const addHubspotTag = () => {
  const loaderId = 'hs-script-loader';
  const scriptExists = document.getElementById(loaderId);

  if (scriptExists) {
    console.error('Hubspot is already loaded');
    return;
  }

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.id = loaderId;
  script.src = 'https://js-na1.hs-scripts.com/7560723.js';
  script.async = true;
  script.defer = true;
  script.setAttribute('data-vendor', 'c:hubspot');

  document.head.appendChild(script);
};

const propagateUserEmail = (email?: string): void => {
  if (!email) {
    return;
  }

  const windowRef: WindowModel = window;
  const _hsq = (windowRef._hsq = windowRef._hsq || []);
  _hsq.push([
    'identify',
    {
      email,
    },
  ]);
};

/**
 * Initializes Hubspot
 * @returns {void}
 */
export const initHubspot = async (): Promise<void> => {
  /* istanbul ignore if */
  if (!isVendorEnabled('c:hubspot')) {
    console.warn('User has not given consent for loading Hubspot');
    return;
  }

  const windowRef: WindowModel = window;
  const status = windowRef?.HubSpotConversations?.widget.status();

  /**
   * If Hubspot is loaded refresh the state, otherwise load hubspot
   */
  if (status?.loaded) {
    windowRef.HubSpotConversations?.widget.refresh();
  } else {
    const token = await getHubspotToken();
    const email = getActiveUserProfile()?.email;
    windowRef.hsConversationsSettings = {
      // do not load hubspot before the identification information is passed through.
      loadImmediately: false,
      identificationEmail: email,
      identificationToken: token,
    };
    addHubspotTag();
    propagateUserEmail(email);

    const onConversationsAPIReady = () => {
      windowRef.HubSpotConversations?.widget.load();
    };
    // If Hubspot is ready, we load, otherwise we store the loading function in the event listener callbacks
    if (windowRef.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      windowRef.hsConversationsOnReady = [onConversationsAPIReady];
    }
  }
};

/**
 * Show the Hubspot chat
 */
export const showHubspot = () => {
  const windowRef: WindowModel = window;

  windowRef?.HubSpotConversations?.widget.open();
};
