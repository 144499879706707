// Our current implementation of Auth0 does not allow us to use their new SDK as we are using our own login page
import { WebAuth, Auth0UserProfile, CheckSessionOptions } from 'auth0-js';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { AuthResult, SocialIdentityProviders, LogoutOptions } from './models/auth.model';
import { environment } from '../constants/index';

let webAuth: WebAuth;
let auth0UserProfile: Auth0UserProfile | undefined;
const AUTH0_STATE_KEY = 'didomiAuth0State';

/**
 * Initializes the Auth0 client
 * @returns {Promise<WebAuth>}
 */
export const initAuth0 = async (): Promise<WebAuth> => {
  webAuth = new WebAuth(environment.authConfig);
  return webAuth;
};

/**
 * Returns the shared instance of auth
 * @returns {WebAuth}
 */
export const getInstance = (): WebAuth => {
  return webAuth;
};

/**
 * Login the user
 * @param {string} - Email
 * @param {string} - Password
 * @returns {Promise<AuthResult>}
 */
export const login = (email: string, password: string): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    getInstance().client.login(
      {
        password,
        realm: environment.authConfig.connection,
        username: email,
        audience: environment.authConfig.audience,
      },
      (err, authResult) => {
        if (err) {
          reject(err);
        } else {
          const token = authResult.accessToken;
          setToken(token);
          resolve(authResult);
        }
      },
    );
  });
};

/**
 * Login the user via social SSO connection (for example google-oauth)
 * This should not be used for enterprise SSO connections (for example SAML or Active Directory)
 * @param {SocialIdentityProviders} identityProvider The identity provider for the connection
 * @param {Record<string, any>} state Any state you want to keep https://auth0.com/docs/libraries/auth0js#webauth-authorize-
 * @returns {void}
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const socialSsoLogin = (identityProvider: SocialIdentityProviders, state?: Record<string, any>): void => {
  if (state) {
    localStorage.setItem(
      AUTH0_STATE_KEY,
      JSON.stringify({
        ...state,
        // https://auth0.com/docs/secure/attack-protection/state-parameters
        xsrf_protection: randomString(),
      }),
    );
  }

  getInstance().authorize({
    connection: identityProvider,
    redirectUri: `${window.location.origin}/auth/callback`,
    responseType: environment.authConfig.responseType,
    scope: environment.authConfig.scope,
    state: localStorage.getItem(AUTH0_STATE_KEY) || undefined,
    // Make sure the user has to select their Google profile every time
    prompt: 'select_account',
  });
};

/**
 * Parse the SSO hash
 * @returns {Promise<Partial<AuthResult>>}
 */
export const parseSsoHash = (hash: string): Promise<Partial<AuthResult>> => {
  const didomiAuth0State = localStorage.getItem(AUTH0_STATE_KEY) || undefined;

  return new Promise((resolve, reject) => {
    getInstance().parseHash({ hash, state: didomiAuth0State }, (err, authResult) => {
      if (didomiAuth0State) {
        localStorage.removeItem(AUTH0_STATE_KEY);
      }

      if (err) {
        reject(err);
      } else if (authResult === null) {
        reject(new Error('authResult is null'));
      } else if (!authResult.accessToken) {
        reject(new Error('authResult does not contain an accessToken'));
      } else {
        setToken(authResult.accessToken);

        // if accessToken is truthy we can safely assume the other properties are available too
        resolve({
          accessToken: authResult.accessToken,
          expiresIn: authResult?.expiresIn,
          tokenType: authResult?.tokenType,
          idToken: authResult?.idToken,
        });
      }
    });
  });
};

/**
 * Check session & recreate a token with a correct audience if the token is opaque
 *
 * The method accepts any valid OAuth2 parameters that would normally be sent to authorize.
 * If you omit them, it will use the ones provided when initializing Auth0.
 *
 * Could be used for session polling with at least 15 mins interval between calls
 * https://auth0.com/docs/libraries/auth0js#polling-with-checksession-
 *
 * @param {any} options - the same options that are passed
 * when a new auth0 client was instantiated
 * @returns { Promise<AuthResult> }
 */
export const checkSession = (options: CheckSessionOptions = {}): Promise<AuthResult> => {
  return new Promise((resolve, reject) => {
    getInstance().checkSession(options, (err, authResult) => {
      if (err) {
        reject(err);
      } else if (authResult === null) {
        reject(new Error('authResult is null'));
      } else if (!authResult.accessToken) {
        reject(new Error('authResult does not contain an accessToken'));
      } else {
        // if accessToken is truthy we can safely assume the other properties are available too
        resolve({
          accessToken: authResult.accessToken,
          expiresIn: authResult?.expiresIn,
          tokenType: authResult?.tokenType,
          idToken: authResult?.idToken,
        });
      }
    });
  });
};

/**
 * Get the user profile information
 * @returns {Promise<Auth0UserProfile>}
 */
export const getUserProfile = (): Promise<Auth0UserProfile> => {
  return new Promise((resolve, reject) => {
    const token = getToken();
    getInstance().client.userInfo(token, (err, profile) => {
      if (err) {
        reject(err);
      } else {
        auth0UserProfile = profile;
        resolve(profile);
      }
    });
  });
};

/**
 * Get the user profile information by provided token
 * It is used to get an user profile from auth0 and
 * do not do any side effects (like settings auth0UserProfile
 * in getUserProfile)
 *
 * TODO refactor later this function and getUserProfile to use
 * one pure function with no side effects
 * @returns {Promise<Auth0UserProfile>}
 */
export const getProfileByToken = (token: string): Promise<Auth0UserProfile> => {
  return new Promise((resolve, reject) => {
    getInstance().client.userInfo(token, (err, profile) => {
      err ? reject(err) : resolve(profile);
    });
  });
};

/**
 * Returns the active (if previously fetched) auth0 user profile
 * @returns {Auth0UserProfile | undefined}
 */
export const getActiveUserProfile = (): Auth0UserProfile | undefined => {
  return auth0UserProfile;
};

/**
 * Logout the user
 * @params {LogoutOptions} the logout option
 * @returns {void}
 */
export const logout = ({ redirectUri, federated }: LogoutOptions = { redirectUri: undefined, federated: false }): void => {
  localStorage.removeItem('token');
  getInstance().logout({
    returnTo: `${location.origin}${redirectUri ? redirectUri : ''}`,
    federated,
  });
};

/**
 * Reset the password
 * @param {string} - Email
 * @returns {Promise<string>}
 */
export const resetPassword = (email: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    getInstance().changePassword(
      {
        email,
        connection: environment.authConfig.connection,
      },
      (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response);
        }
      },
    );
  });
};

/**
 * Check if the token is valid and not expired
 * @returns {boolean}
 */
export const isAuthenticated = (): boolean => {
  const token = getToken();
  if (token) {
    try {
      const decodedToken: JwtPayload = jwt_decode(token);
      const expiry = decodedToken.exp;
      const now = new Date();
      return !!expiry && now.getTime() < expiry * 1000;
    } catch {
      return false;
    }
  }
  return false;
};

/**
 * Returns the token from the local storage
 * @returns {string}
 */
export const getToken = (): string => {
  return localStorage.getItem('token') || '';
};

/**
 * Set the token to the local storage
 * @param {string} - Token
 * @returns {void}
 */
export const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

/**
 * Copied from https://github.com/auth0/auth0.js/blob/master/src/helper/random.js
 * */
export const randomString = (length = 32) => {
  const bytes = new Uint8Array(length);
  const result = [];
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

  const cryptoObj = window.crypto;
  if (!cryptoObj) {
    return Math.random().toString();
  }

  const random = cryptoObj.getRandomValues(bytes);

  for (let a = 0; a < random.length; a++) {
    result.push(charset[random[a] % charset.length]);
  }

  return result.join('');
};

export const redirectToUniversalLogin = (redirectUri?: string): void => {
  const { authConfig } = environment;
  const queryString = `response_type=token&redirect_uri=${redirectUri || authConfig.redirectUri}/auth/callback&client_id=${authConfig.clientID}`;
  const url = `https://${authConfig.domain}/authorize?${queryString}`;
  window.location.replace(url);
};
