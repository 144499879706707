import { SDK_EVENTS } from './models/sdk-events';

const addDidomiSDKTag = () => {
  const loaderId = 'spcloader';
  const scriptExists = document.getElementById(loaderId);

  if (scriptExists) {
    console.error('Didomi SDK is already loaded');
    return;
  }

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://sdk.privacy-center.org/7dd8ec4e-746c-455e-a610-99121b4148df/loader.js?target=${document.location.hostname}`;
  script.id = loaderId;

  document.head.appendChild(script);
};

/**
 * Initializes the Didomi SDK
 * @returns {void}
 */
export const initDidomiSDK = (): void => {
  // Ensure that GDPR gets applied globally
  if (!('gdprAppliesGlobally' in window)) {
    window['gdprAppliesGlobally'] = true;
  }

  addDidomiSDKTag();
};

/**
 * Check if a vendor is enabled
 * @param vendor
 */
export const isVendorEnabled = (vendor: string) => {
  const windowRef: WindowModel = window;
  const userConsentStatuses = windowRef.Didomi?.getUserConsentStatusForAll();

  const enabledVendors = userConsentStatuses?.vendors.enabled;

  return enabledVendors?.includes(vendor);
};

/**
 * Displays the notice banner to the user
 */
export const showNoticeBanner = () => {
  const windowRef: WindowModel = window;

  windowRef.Didomi?.notice?.show();
};

/**
 * Displays the preferences banner to the user
 */
export const showPreferencesBanner = () => {
  const windowRef: WindowModel = window;

  windowRef.Didomi?.preferences?.show();
};

/**
 * Set the user consent status to True for all purposes/vendors
 */
export const setUserAgreeToAll = () => {
  const windowRef: WindowModel = window;

  windowRef.Didomi?.setUserAgreeToAll();
};

/**
 * Create Proxy for SDK events
 * Use this method to create proxy for the SDK events to be able to listen them globally by adding event listener to window's events
 * window.addEventListener(eventName, cb);
 */
export const initEvents = () => {
  const windowRef: WindowModel = window;

  windowRef.didomiEventListeners = windowRef.didomiEventListeners || [];

  SDK_EVENTS.forEach(eventName => {
    windowRef.didomiEventListeners?.push({
      event: eventName,
      /* eslint-disable @typescript-eslint/no-explicit-any */
      listener: /* istanbul ignore next */ (data: any) => window.dispatchEvent(new CustomEvent(eventName, { detail: data })),
    });
  });
};
