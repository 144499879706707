import { DidomiSnackbar, DidomiIcon } from '@didomi/ui-atoms/dist/custom-elements';
import { SnackbarOptions, SnackbarElement, DisplaySnackbarResponse } from './models/snackbar.model';

const SNACKBAR_ANIMATION_DURATION = 300;
const SNACKBAR_TIMEOUT = 3000;
let currentSnackbar: SnackbarElement | undefined;
let currentSnackbarTimeout: number;
let container = document.body;
let animationDown = 0;
let animationUpProgress = 0;
let animationDownProgress = 0;

/**
 * Starts the snackbar display/creation. If there is an existing snackbar open
 * it sets it up to close it first and then open the new one.
 * @param {string} text The snackbar text
 * @param {SnackbarOptions} options The snackbar options
 */
export const displaySnackbar = async (text: string, options?: SnackbarOptions): Promise<DisplaySnackbarResponse> => {
  const newSnackbar = await createSnackbar(text, options);

  if (currentSnackbar) {
    // if the snackbar is open, we close the previous snackbar without animation
    animationDown = 0;
    await removeSnackbar(currentSnackbar);
  }

  return displayNewSnackbar(newSnackbar, options?.duration, options?.permanent);
};

/**
 * Creates the snackbar container that will be used to position the snackbar, the container
 * goes fixed with full width and full height but with no pointer events to allow to click
 * though it
 * @returns {HTMLElement} The created snackbar container
 */
const createSnackbarContainer = (): HTMLElement => {
  const snackbarContainer = document.createElement('div');
  snackbarContainer.style.position = 'fixed';
  snackbarContainer.style.bottom = '0';
  snackbarContainer.style.left = '80px'; // Sidenav
  snackbarContainer.style.right = '0';
  snackbarContainer.style.height = '90px'; // Snackbar + padding
  snackbarContainer.style.display = 'flex';
  snackbarContainer.style.justifyContent = 'center';
  snackbarContainer.style.alignItems = 'flex-end';
  snackbarContainer.style.pointerEvents = 'none';
  return snackbarContainer;
};

/**
 * Creates the snackbar element and sets all the necessary attributes
 * @param {string} text The snackbar text
 * @param {SnackbarOptions} options The snackbar options
 * @returns {SnackbarElement} The created snackbar (container element + actionListener + secondaryActionListener)
 */
const createSnackbar = async (text: string, options?: SnackbarOptions): Promise<SnackbarElement> => {
  const snackbarContainer = createSnackbarContainer();
  // istanbul ignore if - this is just in case
  if (!customElements.get('utility-snack-bar')) {
    customElements.define('utility-snack-bar', DidomiSnackbar);
    customElements.define('utility-snack-bar-didomi-icon', DidomiIcon);
  }
  await customElements.whenDefined('utility-snack-bar');
  const snackbar = document.createElement('utility-snack-bar') as HTMLElement;
  snackbar.setAttribute('role', 'alert');
  snackbar.setAttribute('aria-live', 'assertive');
  snackbar.style.paddingBottom = '20px';
  snackbar.style.pointerEvents = 'auto';
  snackbar.style.opacity = '0';
  snackbar.style.transform = 'translateY(0)';

  snackbar.setAttribute('text', text);
  snackbar.setAttribute('variant', options?.variant || 'message');
  let actionListener;
  let secondaryActionListener;
  if (options?.title) {
    snackbar.setAttribute('snackbar-title', options.title);
  }
  if (options?.id) {
    snackbar.setAttribute('id', options.id);
  }
  if (options?.action) {
    snackbar.setAttribute('action-name', options.action.name);
    actionListener = snackbar.addEventListener(
      'actionClick',
      /* istanbul ignore next - Tested in snackbar component */
      () => {
        if (options.action?.action) {
          options.action?.action();
        }
        if (options.action?.closeInAction) {
          removeCurrentSnackbar();
        }
      },
    );
  }
  if (options?.secondaryAction) {
    snackbar.setAttribute('secondary-action-name', options.secondaryAction.name);
    secondaryActionListener = snackbar.addEventListener(
      'secondaryActionClick',
      /* istanbul ignore next - Tested in snackbar component */
      () => {
        if (options.secondaryAction?.action) {
          options.secondaryAction?.action();
        }
        if (options.secondaryAction?.closeInAction) {
          removeCurrentSnackbar();
        }
      },
    );
  }
  if (!options?.action && !options?.secondaryAction && options?.icon) {
    snackbar.setAttribute('icon-name', options.icon);
  }

  snackbarContainer.appendChild(snackbar);
  return {
    snackbar: snackbarContainer,
    actionListener,
    secondaryActionListener,
  };
};

/**
 * Removes the currently opened snackbar from screen
 */
export const removeCurrentSnackbar = async (): Promise<void> => {
  /* istanbul ignore else  */
  if (currentSnackbar) {
    return await removeSnackbar(currentSnackbar);
  }
};

/**
 * Removes a particular snackbar, it also receives a callback to create a new snackbar
 * after the previous one has been removed
 * @param {SnackbarElement} newSnackbar The snackbar to be removed
 * @param {Function} addNewSnackBarCallback A callback to create a new snackbar
 */
const removeSnackbar = (snackbar: SnackbarElement): Promise<void> => {
  return new Promise(resolve => {
    /* istanbul ignore else  */
    if (snackbar) {
      const snackbarElement = snackbar.snackbar.querySelector('utility-snack-bar') as HTMLElement;

      if (animationDown !== 0) {
        // istanbul ignore next - The snackbar it's going to close itself already
        setTimeout(() => {
          resolve();
        }, animationDownProgress);
      } else {
        /* istanbul ignore if - Tested in snackbar component */
        if (snackbar.actionListener) {
          snackbarElement.removeEventListener('actionClick', snackbar.actionListener);
        }
        /* istanbul ignore if */
        if (snackbar.secondaryActionListener) {
          snackbarElement.removeEventListener('secondaryActionClick', snackbar.secondaryActionListener);
        }

        slideDown(snackbarElement, () => {
          container.removeChild(snackbar.snackbar);
          if (currentSnackbarTimeout) {
            clearTimeout(currentSnackbarTimeout);
          }
          currentSnackbar = undefined;
          resolve();
        });
      }
    }
  });
};

/**
 * Creates a new snackbar and appends it to the body, adds the proper transition properties and
 * sets the timeout to close the snackbar
 * @param {SnackbarElement} newSnackbar The snackbar to be created
 * @returns {DisplaySnackbarResponse}
 */
const displayNewSnackbar = (newSnackbar: SnackbarElement, duration = SNACKBAR_TIMEOUT, permanent = false): DisplaySnackbarResponse => {
  currentSnackbar = newSnackbar;
  const snackbarElement = newSnackbar.snackbar.firstChild as HTMLElement;
  container = (document.body.getElementsByClassName('didomi-overlay-container')[0] as HTMLElement) || document.body;

  container.appendChild(newSnackbar.snackbar);
  slideUp(snackbarElement);

  if (!permanent) {
    currentSnackbarTimeout = window.setTimeout(async () => await removeCurrentSnackbar(), duration);
  }

  return {
    id: newSnackbar.snackbar.getAttribute('id'),
    close: () => removeSnackbar(newSnackbar),
  };
};

/**
 * Animates the snackbar up
 * @param {HTMLElement} element The snackbar to moved up
 */
const slideUp = (element: HTMLElement): void => {
  let start: number;

  const step = (timestamp: number) => {
    if (start === undefined) start = timestamp;
    animationUpProgress = timestamp - start;
    element.style.opacity = ((animationUpProgress * 1) / SNACKBAR_ANIMATION_DURATION).toString();
    element.style.transform = 'translateY(-' + Math.ceil((animationUpProgress * 12) / SNACKBAR_ANIMATION_DURATION) + 'px)';

    if (animationUpProgress < SNACKBAR_ANIMATION_DURATION) {
      window.requestAnimationFrame(step);
    } else {
      element.style.opacity = '1';
      element.style.transform = 'translateY(-12px)';
      animationUpProgress = 0;
    }
  };

  window.requestAnimationFrame(step);
};

/**
 * Animates the snackbar down
 * @param {HTMLElement} element The snackbar to moved down
 * @param {() => void} destroyCallback A function to perform after the animation finished
 */
const slideDown = (element: HTMLElement, destroyCallback: () => void): void => {
  let start: number;

  const step = (timestamp: number) => {
    if (start === undefined) start = timestamp;
    animationDownProgress = timestamp - start;
    element.style.opacity = (1 - (animationDownProgress * 1) / SNACKBAR_ANIMATION_DURATION).toString();
    element.style.transform = 'translateY(-' + (12 - (animationDownProgress * 12) / SNACKBAR_ANIMATION_DURATION).toString() + 'px)';
    if (animationDownProgress < SNACKBAR_ANIMATION_DURATION) {
      animationDown = window.requestAnimationFrame(step);
    } else {
      destroyCallback();
      animationDown = 0;
      animationDownProgress = 0;
    }
  };

  animationDown = window.requestAnimationFrame(step);
};
