import { Environment } from './models/environment.model';

export let environment: Environment = {
  isDevelopment: CONFIG.environmentConfig.environment === 'development',
  isProduction: CONFIG.environmentConfig.environment === 'production',
  isStaging: CONFIG.environmentConfig.environment === 'staging',
  assetsBaseUrl: CONFIG.environmentConfig.assetsBaseUrl,
  apiBaseUrl: CONFIG.environmentConfig.apiBaseUrl,
  authConfig: CONFIG.authConfig,
};

export const updateEnvironment = (newEnvironment: Partial<Environment>) => {
  environment = {
    ...environment,
    ...newEnvironment,
  };
};
