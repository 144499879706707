import { Organizations, Auth0, FeatureFlags, SDK, ReleaseFlags, UserProfile } from '../didomi-utility';

import { StandaloneConfig } from './models/standalone.model';
import configStaging from '../../config/staging';

import { updateEnvironment } from '../constants/index';

/**
 * Initializes the Standalone app
 *
 * we are creating an authenticated environment to be able to run the standalone app as if it was integrated into the Shell.
 * Currently we are taking care of:
 * - Setting the default config (Standalone mode will never run on production, we make sure that it receives the staging config or a custom one)
 * - Initiating the SDK (otherwise done by the Shell)
 * - Initiating the authentication and request credentials (otherwise done by the auth SPA)
 * - Connecting the feature flags (otherwise done by the Shell)
 * - Getting the organizations and setting the first one as active (otherwise done by the root-config)
 *
 * @returns {Promise<void>}
 */
export const initApp = async (config?: StandaloneConfig): Promise<void> => {
  updateEnvironment({
    authConfig: config?.authConfig || configStaging.authConfig,
    assetsBaseUrl: config?.assetsBaseUrl || configStaging.environmentConfig.assetsBaseUrl,
    apiBaseUrl: config?.apiBaseUrl || configStaging.environmentConfig.apiBaseUrl,
  });

  SDK.initDidomiSDK();
  SDK.initEvents();
  await Auth0.initAuth0();
  if (!Auth0.isAuthenticated()) {
    const email = prompt('enter email', '') as string;
    const password = prompt('enter password', '') as string;
    await Auth0.login(email, password);
  }
  FeatureFlags.connectFeatureFlags();
  ReleaseFlags.connectReleaseFlags();

  // Load user profile
  await UserProfile.getUserProfile();

  // Setup organization
  const organizations = await Organizations.getOrganizations();
  const orgId = Organizations.getActiveOrganizationId();
  const path = window.location.pathname;
  const startingPath = path.split('/')[1];
  const isOrgValid = organizations && organizations.some(org => org.id === startingPath);

  // The user is not member of any organizations
  // This could happen to a new self-registered user or a user whose membership has been removed by an admin
  if (!organizations || organizations.length === 0) {
    return;
  }

  // If the URL path corresponding to the organization doesn't match the active organization and the org in the path is a valid org
  // let's update the active org to reflect that otherwise we use the default approach
  // Similar approach to the Shell: https://gitlab.com/didomi/console-next/shell/-/blob/master/root-config/src/didomi-spa-root-config.ts#L143
  if (startingPath !== orgId && isOrgValid) {
    Organizations.setActiveOrganizationId(startingPath);
  } else {
    Organizations.setActiveOrganizationId(organizations[0].id || '');
  }
};
