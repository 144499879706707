let _isOpen = false;

/**
 * Opens the snackbar
 */
export const open = (): void => {
  _isOpen = true;
  window.dispatchEvent(new CustomEvent('sidenavToggle', { detail: true }));
};

/**
 * Closes the snackbar
 */
export const close = (): void => {
  _isOpen = false;
  window.dispatchEvent(new CustomEvent('sidenavToggle', { detail: false }));
};

/**
 * Returns the status of the sidenav
 */
export const isOpen = (): boolean => {
  return _isOpen;
};
