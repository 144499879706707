export const SDK_EVENTS = [
  'consent.changed',
  'consent.pendingchanged',
  'integrations.consentpassedtodfp',
  'notice.backdropclick',
  'notice.clickagree',
  'notice.clickclose',
  'notice.clickdisagree',
  'notice.clickmoreinfo',
  'notice.clickviewvendors',
  'notice.hidden',
  'notice.shown',
  'preferences.clickagreetoall',
  'preferences.clickcategoryagree',
  'preferences.clickcategorydisagree',
  'preferences.clickclose',
  'preferences.clickdisagreetoall',
  'preferences.clickpurposeagree',
  'preferences.clickpurposedisagree',
  'preferences.clicksavechoices',
  'preferences.clickvendoragree',
  'preferences.clickvendordisagree',
  'preferences.clickvendorsavechoices',
  'preferences.clickviewvendors',
  'preferences.hidden',
  'preferences.shown',
];
