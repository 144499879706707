// TODO: share
import { __awaiter, __generator } from "tslib";
import { SIGN_IN_PATH } from '../constants';
var defaultInterceptorOpts = {
    onExpiredToken: null,
    onForbidden: null,
};
export var fetchWithResponseInterceptors = function (resource, config, interceptorOptions) {
    if (interceptorOptions === void 0) { interceptorOptions = defaultInterceptorOpts; }
    return __awaiter(void 0, void 0, void 0, function () {
        var res, resClone, newClone, parsedResponse, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(resource, config)];
                case 1:
                    res = _b.sent();
                    resClone = res.clone();
                    if (interceptorOptions.onForbidden) {
                        if (res.status === 403 && !window.location.href.includes(SIGN_IN_PATH)) {
                            interceptorOptions.onForbidden(resClone);
                            return [2 /*return*/, res];
                        }
                    }
                    if (!interceptorOptions.onExpiredToken) return [3 /*break*/, 6];
                    newClone = resClone.clone();
                    parsedResponse = {};
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, resClone.json()];
                case 3:
                    parsedResponse = _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _b.sent();
                    return [2 /*return*/, newClone];
                case 5:
                    if (res.status === 401 && ((_a = parsedResponse === null || parsedResponse === void 0 ? void 0 : parsedResponse.data) === null || _a === void 0 ? void 0 : _a.message) === 'jwt expired' && !window.location.href.includes(SIGN_IN_PATH)) {
                        interceptorOptions.onExpiredToken(res);
                        return [2 /*return*/, newClone];
                    }
                    resClone = newClone;
                    _b.label = 6;
                case 6: return [2 /*return*/, resClone];
            }
        });
    });
};
