import { __assign } from "tslib";
import { SIGN_IN_PATH } from '../constants';
var defaultInterceptorOpts = {
    onExpiredToken: null,
    onForbidden: null,
};
// Keep track of the return value (which is an index) of each interceptor so we can remove them later
// https://github.com/axios/axios/blob/master/lib/core/InterceptorManager.js#L32
var interceptorsPerInstance = new Map();
var addInterceptors = {
    onExpiredToken: function (axiosInstance, cb) {
        return axiosInstance.interceptors.response.use(function logOutOnExpiredTokenFulfilled(response) {
            return response;
        }, function onExpiredTokenError(error) {
            var _a, _b;
            // When navigating (both within and across SPAs), we already have a mechanism to logout on expired tokens
            // Make sure we only logout when needed (for example on clicking a save button)
            if (error.response.status === 401 && ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) === 'jwt expired' && !window.location.href.includes(SIGN_IN_PATH)) {
                cb(error);
            }
            return Promise.reject(error);
        });
    },
    onForbidden: function (axiosInstance, cb) {
        return axiosInstance.interceptors.response.use(function logOutOnForbiddenFulfilled(response) {
            return response;
        }, function onForbiddenError(error) {
            if (error.response.status === 403 && !window.location.href.includes(SIGN_IN_PATH)) {
                cb(error);
            }
            return Promise.reject(error);
        });
    },
};
export var addAxiosResponseInterceptors = function (axiosInstance, interceptorOptions) {
    if (interceptorOptions === void 0) { interceptorOptions = defaultInterceptorOpts; }
    // Looping over defaultOpts to ensure we handle all possible interceptors
    Object.keys(defaultInterceptorOpts).forEach(function (key) {
        var cb = interceptorOptions[key];
        // Note: each instance of axios could have different interceptors enabled
        var enabledInterceptorsForInstance = interceptorsPerInstance.get(axiosInstance);
        var isAlreadyEnabled = enabledInterceptorsForInstance && enabledInterceptorsForInstance[key] !== undefined;
        if (cb && !isAlreadyEnabled) {
            var interceptorId = addInterceptors[key](axiosInstance, cb);
            registerInterceptor(axiosInstance, key, interceptorId);
        }
        else if (isAlreadyEnabled && !cb) {
            axiosInstance.interceptors.response.eject(enabledInterceptorsForInstance[key]);
            deregisterInterceptor(axiosInstance, key);
        }
    });
    return axiosInstance;
};
function registerInterceptor(axiosInstance, key, interceptorId) {
    var _a, _b;
    if (!interceptorsPerInstance.get(axiosInstance)) {
        interceptorsPerInstance.set(axiosInstance, (_a = {},
            _a[key] = interceptorId,
            _a));
    }
    interceptorsPerInstance.set(axiosInstance, __assign(__assign({}, interceptorsPerInstance.get(axiosInstance)), (_b = {}, _b[key] = interceptorId, _b)));
}
function deregisterInterceptor(axiosInstance, key) {
    var registeredInterceptorsForInstance = __assign({}, interceptorsPerInstance.get(axiosInstance));
    delete registeredInterceptorsForInstance[key];
    interceptorsPerInstance.set(axiosInstance, registeredInterceptorsForInstance);
}
