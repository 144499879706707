import { environment } from '../constants';
import './models/google-tag-manager.model';

const DIDOMI_GTM_ID = 'GTM-PB5RMPK';

/**
 * It appends the google tag manager script to the body.
 * @returns {Promise<void>}
 */
export const initGTM = (tagName = DIDOMI_GTM_ID): void => {
  if (!environment.isProduction && !environment.isStaging) {
    console.error('GTM will be only initialized in staging/production environment.');
    return;
  }

  if (document.getElementById(tagName)) {
    console.error('GTM has been already initialized under ' + tagName);
    return;
  }

  window['dataLayer'] = window['dataLayer'] || [];
  window['dataLayer'].push({
    'gtm.start': new Date().getTime(),
    'event': 'gtm.js',
  });

  const firstScript = document.getElementsByTagName('script')[0];

  const gtmScript = document.createElement('script');
  gtmScript.type = 'text/javascript';
  gtmScript.async = true;
  gtmScript.id = tagName;
  gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + tagName;

  firstScript?.parentNode ? firstScript.parentNode.insertBefore(gtmScript, firstScript) : document.head.prepend(gtmScript);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const pushValueToDataLayer = (variableName: string, variableValue: any) => {
  if (!window['dataLayer']) {
    console.error('GTM is not set-up.');
    return;
  }
  window['dataLayer'].push({ [variableName]: variableValue });
};

export const pushEventToDataLayer = (eventName: string, eventValue?: any) => {
  if (!window['dataLayer']) {
    console.error('GTM is not set-up.');
    return;
  }
  window['dataLayer'].push({ event: eventName, ...eventValue });
};
