import { environment } from '../constants';

interface OrgSeedPolicies {
  id: string;
  access_policy_ids: string[];
}

/*
 * Allow policies seeding from the localStorage for non-prod env(for testing and development)
 *
 * Use next code to save seeds in localStorage:
 * =======================
 * const roles = {
 *   didomi: { id: 'test-role', access_policy_ids: ['marketplace_viewer', 'users_editor'] },
 *   myOrgName: {id: 'test-role2', access_policy_ids: ['admin'], }
 * }
 * localStorage.setItem('dev-access-policies', JSON.stringify(roles));
 * =======================
 */
export const getDevAccessPolicies = (): { roles?: Record<string, OrgSeedPolicies> } => {
  const devPolicies = localStorage.getItem('dev-access-policies');

  return devPolicies && !environment.isProduction ? { roles: JSON.parse(devPolicies) } : {};
};
