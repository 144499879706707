import { getActiveOrganizationId } from '../organizations';
import { DidomiUtilityEventMap } from '../typings/didomiUtilityEvents';
import { BlockedRoute } from './models/router.model';

let blocked = false;

let blockedPaths: BlockedRoute[] = [];

const sanitizePath = (path: string) => {
  const prefix = path.startsWith('/') ? '' : '/';

  return prefix + path;
};

/**
 * Returns true if the router is blocked
 * @returns {boolean}
 */
export const isBlocked = (): boolean => {
  return blocked;
};

/**
 * Returns the list of routes the user tried to access while the router is blocked
 * @returns {BlockedRoute[]}
 */
export const getBlockedPaths = (): BlockedRoute[] => {
  return blockedPaths;
};

/**
 * Will block any further navigation, any attempt to change the route will be stored
 * in the blockedPaths variable and an event will be emitted with the blocked path details
 */
export const block = (): void => {
  /* istanbul ignore else */
  if (!blocked) {
    blocked = true;
  }
};

/**
 * Will allow navigation to happen if the router was previously blocked and clear the block paths
 * history
 */
export const unblock = (): void => {
  /* istanbul ignore else */
  if (blocked) {
    blocked = false;
    blockedPaths = [];
  }
};

/**
 * Will navigate to a path starting with the active organization id in front.
 * if 'replaceState' is true it will rewrite browser's history.
 * It's useful when we want to prevent the user from going back
 */
export const navigateTo = (route: string, replaceState = false): void => {
  const currentOrgId = getActiveOrganizationId();
  const method: 'pushState' | 'replaceState' = replaceState ? 'replaceState' : 'pushState';

  const path = `/${currentOrgId}${sanitizePath(route)}`;

  if (blocked) {
    const blockedNavigation = { path, method };
    blockedPaths.push(blockedNavigation);
    const routeBlockedEvent: DidomiUtilityEventMap['routeBlocked'] = new CustomEvent('routeBlocked', { detail: blockedNavigation });
    window.dispatchEvent(routeBlockedEvent);
    return;
  }

  if (!currentOrgId) {
    return window.history[method](null, '', '/');
  }

  return window.history[method](null, '', path);
};

/**
 * Will navigate to an absolute path without the active orgId
 */
export const navigateToAbsolute = (route: string, replaceState = false): void => {
  const path = sanitizePath(route);
  const method: 'pushState' | 'replaceState' = replaceState ? 'replaceState' : 'pushState';

  if (blocked) {
    const blockedNavigation = { path, method };
    blockedPaths.push(blockedNavigation);
    const routeBlockedEvent: DidomiUtilityEventMap['routeBlocked'] = new CustomEvent('routeBlocked', { detail: blockedNavigation });
    window.dispatchEvent(routeBlockedEvent);
    return;
  }

  window.history[method](null, '', path);
};
