import { Organizations } from '../didomi-utility';
import { Feature } from '../organizations/models/organizations.model';
import { DidomiUtilityEventMap } from '../typings/didomiUtilityEvents';

let releaseFlags: Feature[];
let isUpdatingList = false;

/**
 * It ensures release flags are updated every-time an organization changes or the first time they are fetch
 */
export const connectReleaseFlags = (): void => {
  window.addEventListener('orgSetup', updateReleaseFlagsListFromOrgChange);
  window.addEventListener('orgChanged', updateReleaseFlagsListFromOrgChange);
};

/**
 * It removes the event listener that expects an organization change to update the release flag list
 */
export const disconnectReleaseFlags = (): void => {
  window.removeEventListener('orgSetup', updateReleaseFlagsListFromOrgChange);
  window.removeEventListener('orgChanged', updateReleaseFlagsListFromOrgChange);
};

/**
 * It updates the release flag list based on the org change event
 * If there is an error while fetching the release flag list it dispatches an error event with the details
 * @param {CustomEvent<string>} orgChangeEvent The org change event
 * @returns {Promise<void>}
 */
const updateReleaseFlagsListFromOrgChange = async (orgChangeEvent: DidomiUtilityEventMap['orgChanged']): Promise<void> => {
  const orgId = orgChangeEvent.detail;
  try {
    await updateReleaseFlagsList(orgId);
  } catch (e) /* istanbul ignore next */ {
    const errorEvent: DidomiUtilityEventMap['errorUpdatingFeatureFlagList'] = new CustomEvent('errorUpdatingReleaseFlagsList', { detail: e });
    window.dispatchEvent(errorEvent);
  }
};

/**
 * It updates the release flag list for a particular organization
 * @param {string} orgId The organization id
 * @returns {Promise<void>}
 */
export const updateReleaseFlagsList = async (orgId: string): Promise<void> => {
  isUpdatingList = true;
  window.dispatchEvent(new Event('updatingReleaseFlagsList'));
  releaseFlags = getReleaseFlagsForOrg(orgId);
  isUpdatingList = false;
  window.dispatchEvent(new Event('updatedReleaseFlagsList'));
};

/**
 * It returns a list of release flags for a particular organization
 * @param {string} orgId The organization id
 * @returns {Promise<ReleaseFlags[]>}
 */
const getReleaseFlagsForOrg = (orgId: string): Feature[] => {
  const allFeatures = Organizations.getOrganizationsList().find(o => o.id === orgId)?.all_features;
  return allFeatures || [];
};

/**
 * Verifies if the current active organization has a release flag
 * @param {string} releaseFlag The release flag to check
 * @returns {Promise<boolean>} A promise that resolves to true if the release flag is enabled
 */
export const isEnabled = async (releaseFlag: string): Promise<boolean> => {
  return new Promise(function (resolve) {
    const isReleaseFlagEnabled: () => boolean = () => {
      // all the release flags are enable for Didomi organizations
      const didomiOrganizations: Array<string | null> = ['qa-didomi', 'didomi-pre-release'];

      if (didomiOrganizations.includes(Organizations.getActiveOrganizationId())) {
        return true;
      }

      const result = releaseFlags?.find(r => r.id === releaseFlag);
      return result ? result.enabled : false;
    };

    /* istanbul ignore if - no need to test this */
    if (isUpdatingList) {
      window.addEventListener('updatedReleaseFlagsList', () => resolve(isReleaseFlagEnabled()), { once: true });
    } else {
      resolve(isReleaseFlagEnabled());
    }
  });
};

/**
 * Verifies if the current active organization has a release flag
 * This will only return a valid value if the release flag list has been updated and is not
 * currently being updated.
 * If the RF list has not been loaded it will return false.
 * Use this method ONLY if you cannot use an async function to validate your FF
 * @param {string} releaseFlag The release to check
 * @returns {boolean} True if the feature is enabled
 */
export const isEnabledSync = (releaseFlag: string): boolean => {
  if (!releaseFlags) return false;

  const didomiOrganizations: Array<string | null> = ['qa-didomi', 'didomi-pre-release'];

  if (didomiOrganizations.includes(Organizations.getActiveOrganizationId())) {
    return true;
  }

  const result = releaseFlags?.find(r => r.id === releaseFlag);
  return result ? result.enabled : false;
};
