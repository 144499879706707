import { Organization } from './models/organizations.model';
import { environment } from '../constants';
import { getToken, logout } from '../auth';
import { isBlocked } from '../router';
import { DidomiUtilityEventMap } from '../typings/didomiUtilityEvents';
import { fetchWithResponseInterceptors } from '@didomi/helpers/dist/response-interceptors/fetch/fetch-response-interceptors';

let organizationsList: Organization[] = [];

let activeOrganizationId: string | null = localStorage.getItem('activeOrg');

/**
 * It fetches the list of available organizations from Didomi's API
 * @returns {Promise<Organization[]>}
 */
export const getOrganizations = async (): Promise<Organization[] | undefined> => {
  const organizationsRequest = await fetchWithResponseInterceptors(
    `${environment.apiBaseUrl}/organizations?$limit=5000`,
    {
      method: 'GET',
      headers: new Headers({
        accept: 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
    },
    { onExpiredToken: logout, onForbidden: logout },
  );
  const organizationsData = await organizationsRequest.json();
  organizationsList = organizationsData?.data;

  checkActiveOrg(organizationsList);

  return organizationsList;
};

const checkActiveOrg = (organizationsList: Organization[]) => {
  // Don't do anything if there are no organizations for this user
  if (!organizationsList?.length) {
    activeOrganizationId = null;
    return;
  }

  // If no active org or if active org not in the list - set first org from the list as active
  if (!activeOrganizationId || !organizationsList.find(org => org.id === activeOrganizationId)) {
    setActiveOrganizationId(organizationsList[0].id);
  }

  const setUpOrgEvent: DidomiUtilityEventMap['orgSetup'] = new CustomEvent('orgSetup', {
    detail: activeOrganizationId || organizationsList[0]?.id,
  });
  window.dispatchEvent(setUpOrgEvent);
};

/**
 * It returns the active organization id
 * @returns {string | null} The current active organization id
 */
export const getActiveOrganizationId = (): string | null => {
  return activeOrganizationId;
};

/**
 * Sets the active organization
 * @param {string} id The organization id
 */
export const setActiveOrganizationId = (id: string): void => {
  if (!organizationsList?.length) {
    throw new Error('There are no organizations, please fetch organizations first');
  }
  // If the router is blocked we can't change the org, a different event is fired, is up to the SPAs to handle the logic here
  if (isBlocked()) {
    const orgBlockedEvent: DidomiUtilityEventMap['orgChangeBlocked'] = new CustomEvent('orgChangedBlocked', { detail: id });
    window.dispatchEvent(orgBlockedEvent);
    return;
  }
  if (organizationsList.some(o => o.id === id)) {
    localStorage.setItem('activeOrg', id);
    activeOrganizationId = id;
    // We send the event with the new organization
    const orgChangedEvent: DidomiUtilityEventMap['orgChanged'] = new CustomEvent('orgChanged', { detail: id });
    window.dispatchEvent(orgChangedEvent);
  } else {
    throw new Error('The id provided does not belong to an organization in the list');
  }
};

/**
 * It returns the list of organizations for the user from memory if you want to update
 * the values from the server you might want to check on getOranizations method
 * @returns {Organization[]} The list of organizations
 */
export const getOrganizationsList = (): Organization[] => {
  return organizationsList;
};

/**
 * It returns the active organization
 * @returns {Organization | undefined} The current active organization
 */
export const getActiveOrganization = (): Organization | undefined => {
  if (!organizationsList?.length) {
    return undefined;
  }

  return organizationsList.find(o => o.id === activeOrganizationId);
};

/**
 * Update an organization (for example after PATCH /organizations/{id})
 * @returns {void}
 * */
export const updateOrganization = (updatedOrg: Organization): void => {
  if (!updatedOrg || !organizationsList?.length) {
    return;
  }

  organizationsList = organizationsList.map(org => {
    if (org.id === updatedOrg.id) {
      return updatedOrg;
    }

    return org;
  });

  // Let the SPAs know that one of the organizations has been updated
  const updateOrgEvent: DidomiUtilityEventMap['orgUpdated'] = new CustomEvent('orgUpdated', { detail: updatedOrg });
  window.dispatchEvent(updateOrgEvent);
};
