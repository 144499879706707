import { EnhancedUserProfile, UserProfile } from './models/user-profile.model';
import { environment } from '../constants';
import { getToken, logout } from '../auth';
import { pushEventToDataLayer } from '../google-tag-manager';
import { fetchWithResponseInterceptors } from '@didomi/helpers';

let userProfile: EnhancedUserProfile | undefined;

/**
 * It returns the active version of the user profile
 * @returns {EnhancedUserProfile | undefined}
 */
export const getActiveUserProfile = (): EnhancedUserProfile | undefined => {
  return userProfile;
};

/**
 * It fetches the list of available user profile from Didomi's API if not fetched before
 * @returns {Promise<EnhancedUserProfile | undefined>}
 */
export const getUserProfile = async (): Promise<EnhancedUserProfile | undefined> => {
  if (userProfile) return userProfile;

  try {
    const userProfileRequest = await fetchWithResponseInterceptors(
      `${environment.apiBaseUrl}/users/me`,
      {
        method: 'GET',
        headers: new Headers({
          accept: 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      },
      { onExpiredToken: logout, onForbidden: logout },
    );
    const userProfileData = await userProfileRequest.json();
    userProfile = enhanceUserProfile(userProfileData);

    pushEventToDataLayer('user-profile-loaded', {
      email: userProfile?.email,
      id: userProfile?.id,
    });

    window.dispatchEvent(
      new CustomEvent('user-profile-loaded', {
        detail: {
          email: userProfile?.email,
          id: userProfile?.id,
        },
      }),
    );

    return userProfile;
  } catch (e) {
    return undefined;
  }
};

/**
 * It clears the existing fetch user profile
 */
export const clearUserProfile = (): void => {
  userProfile = undefined;
};

/**
 * Update the user profile, for example after changing the user's name
 */
export const updateUserProfile = (updatedUserProfile: UserProfile): void => {
  userProfile = enhanceUserProfile(updatedUserProfile);
};

const enhanceUserProfile = (userProfile: UserProfile): EnhancedUserProfile => {
  const auth0Id = userProfile.auth0_id;
  const socialIDPs = ['google-oauth2', 'windowslive'];

  const isSocial = socialIDPs.some(idp => auth0Id.startsWith(idp));
  const isSAML = auth0Id.startsWith('samlp');
  const isUsernamePassword = auth0Id.startsWith('auth0');

  return {
    ...userProfile,
    isSAML,
    isSocial,
    isUsernamePassword,
  };
};
