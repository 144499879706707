import { FeatureFlags, FeatureList, FeatureFlagsTypes } from './models/feature-flags.model';
import { environment } from '../constants';
import { getToken, logout } from '../auth';
import { DidomiUtilityEventMap } from '../typings/didomiUtilityEvents';
import { fetchWithResponseInterceptors } from '@didomi/helpers';

let featureFlags: FeatureFlags;
let isUpdatingList: boolean;

/**
 * It updates the feature flag list based on the org change event
 * If there is an error while fetching the feature flag list it dispatches an error event with the details
 * @param {CustomEvent<string>} orgChangeEvent The org change event
 * @returns {Promise<void>}
 */
const updateFeatureFlagListFromOrgChange = async (orgChangeEvent: DidomiUtilityEventMap['orgChanged']): Promise<void> => {
  const orgId = orgChangeEvent.detail;
  try {
    await updateFeatureFlagsList(orgId);
  } catch (e) {
    const errorUpdatingFeatureFlagList: DidomiUtilityEventMap['errorUpdatingFeatureFlagList'] = new CustomEvent('errorUpdatingFeatureFlagList', { detail: e });
    window.dispatchEvent(errorUpdatingFeatureFlagList);
  }
};

/**
 * It fetches the list of feature flags for a particular organization and returns the result
 * @param {string} orgId The organization id
 * @returns {Promise<FeatureFlags[]>}
 */
const getFeatureFlagsForOrg = async (orgId: string): Promise<FeatureFlags> => {
  const featureFlagsRequest = await fetchWithResponseInterceptors(
    `${environment.apiBaseUrl}/premium-features?organization_id=${orgId}`,
    {
      method: 'GET',
      headers: new Headers({
        accept: 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
    },
    {
      onExpiredToken: logout,
      onForbidden: logout,
    },
  );

  const featureFlagData: FeatureList = await featureFlagsRequest.json();
  return featureFlagData?.data[0];
};

/**
 * It ensures feature flags are updated every-time an organization changes or the first time they are fetch
 */
export const connectFeatureFlags = (): void => {
  window.addEventListener('orgSetup', updateFeatureFlagListFromOrgChange);
  window.addEventListener('orgChanged', updateFeatureFlagListFromOrgChange);
};

/**
 * It removes the event listener that expects an organization change to update the feature flag list
 */
export const disconnectFeatureFlags = (): void => {
  window.removeEventListener('orgSetup', updateFeatureFlagListFromOrgChange);
  window.removeEventListener('orgChanged', updateFeatureFlagListFromOrgChange);
};
/**
 * It updates the feature flag list for a particular organization
 * @param {string} orgId The organization id
 * @returns {Promise<void>}
 */
export const updateFeatureFlagsList = async (orgId: string): Promise<void> => {
  isUpdatingList = true;
  window.dispatchEvent(new Event('updatingFeatureFlagList'));
  featureFlags = await getFeatureFlagsForOrg(orgId);
  isUpdatingList = false;
  window.dispatchEvent(new Event('updatedFeatureFlagList'));
};

/**
 * Verifies if the current active organization has a feature flag
 * @param {FeatureFlagsTypes} featureFlag The feature to check
 * @returns {Promise<boolean>} A promise that resolves to true if the feature is enabled
 */
export const isEnabled = async (featureFlag: FeatureFlagsTypes): Promise<boolean> => {
  return new Promise(function (resolve) {
    if (isUpdatingList) {
      window.addEventListener(
        'updatedFeatureFlagList',
        () => {
          resolve(!!featureFlags && !!featureFlags[featureFlag]);
        },
        { once: true },
      );
    } else {
      resolve(!!featureFlags && !!featureFlags[featureFlag]);
    }
  });
};

/**
 * Verifies if the current active organization has a feature flag
 * This will only return a valid value if the feature flag list has been updated and is not
 * currently being updated.
 * If the FF list has not been loaded it will return false.
 * Use this method ONLY if you cannot use an async function to validate your FF
 * @param {FeatureFlagsTypes} featureFlag The feature to check
 * @returns {boolean} True if the feature is enabled
 */
export const isEnabledSync = (featureFlag: FeatureFlagsTypes): boolean => {
  return !!featureFlags && !!featureFlags[featureFlag];
};
