module.exports = {
  authConfig: {
    connection: 'Username-Password-Authentication',
    clientID: 'SFZCx3ZX3vxaR4FtDcgx9B54GduRMhAf',
    domain: 'auth.staging.didomi.io',
    audience: 'https://api-staging.didomi.io/',
    redirectUri: 'https://console.staging.didomi.io',
    responseType: 'token id_token',
    scope: 'openid email profile',
  },
  environmentConfig: {
    environment: 'staging',
    assetsBaseUrl: 'https://uilib.didomi.io/assets',
    apiBaseUrl: 'https://api-staging.didomi.io',
  },
  intercom: {
    appId: 'c5hm9qkp',
  },
};
